module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dori Art - Doris Müller","short_name":"Dori Art - Doris Müller","start_url":"/","background_color":"#890620ff","theme_color":"#890620ff","display":"minimal-ui","icon":"src/images/doris_1.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ae98535f984aa768d569b540993e4a3"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
